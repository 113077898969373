<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <strong>
          </strong> Devices in: {{warehouse.name}}
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details

          ></v-text-field>
        </v-card-title>
        <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click="moveDevices"
            :disabled="selectedRows.length === 0"
        >
          Move Location
          <v-icon
              right
              dark
          >
            mdi-folder
          </v-icon>
        </v-btn>
        <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click="assignDevices"
            :disabled="selectedRows.length === 0"
        >
          Assign to Partner
          <v-icon
              right
              dark
          >
            mdi-folder
          </v-icon>
        </v-btn>
        <v-col></v-col>
        <v-data-table
            :headers="deviceHeaders"
            :items="warehouse.devices"
            item-key="id"
            class="elevation-1 table-one"
            multi-sort
            :search="search"
            v-model="selectedRows"
            show-select
        >
          <template v-slot:item.name="{item}">
            <div class="d-flex align-center">
              <p class="ma-0 font-weight-medium">
                {{ item.name }}
              </p>
            </div>
          </template>
          <template v-slot:item.action="{item}">
            <div class="d-flex">

              <v-btn
                  color="green"
                  dark
                  outlined
                  @click.stop="viewDevice(item.id)">
                Photos
              </v-btn>
              <v-btn
                  color="red"
                  dark
                  outlined
                  @click="delRecord(item.id)">
                Delete
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </base-card>
    </v-col>

    <AssignDialog ref="assignDialog" @setPartner="setTargetPartner" />
    <MoveDialog ref="moveDialog" @setWarehouse="setTargetWarehouse" />
  </v-row>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import AssignDialog from "@/components/shared/AssignDialog";
import toastr from "toastr";
import MoveDialog from "@/components/shared/MoveDialog.vue";

export default {
  components: {MoveDialog, AssignDialog},
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'View warehouse'
  },
  data() {
    return {
      targetWarehouseId: '',
      targetPartnerId: '',
      search: '',
      items: [],
      selectedRows: [],
      warehouse: {
      },
      tab: 0,
      model: 0,
      photoProps: [
        'controlConnectionPointPhoto',
        'powerConnectionPointPhoto',
        'coldSensorPositionPhoto',
        'returnAirSensorPositionPhoto',
        'generalInstallationPhoto',
        'photos2Installation',
        'photos2ThermostatController'
      ],
      deviceHeaders: [
        {
          text: 'Device Type',
          sortable: true,
          value: 'device_type',
          align: 'left',
        },
        {
          text: 'Serial No',
          sortable: true,
          value: 'serial',
          align: 'left',
        },
        {
          text: 'Customer',
          sortable: true,
          value: 'metadata.customerName',
          align: 'left',
        },
        {
          text: 'Site',
          sortable: true,
          value: 'metadata.site',
          align: 'middle',
        },
        {
          text: 'Installer',
          sortable: true,
          value: 'metadata.installerName',
          align: 'middle',
        },
        {
          text: 'Actions',
          value: 'action',
        }
      ],
    }
  },
  computed: {
    selectedDeviceIds() {
      return this.selectedRows.map(a => a.id);
    }
  },
  methods: {
    setTargetWarehouse(targetId) {
      console.log(targetId);
      this.targetWarehouseId = targetId
    },
    setTargetPartner(targetId) {
      console.log(targetId);
      this.targetPartnerId = targetId
    },
    getwarehouse() {
      if (this.$route.params.id !== null) {
        this.$store.dispatch('warehouses/get', this.$route.params.id).then((result) => {
              this.warehouse = result;
            }
        );
      }
    },
    async delRecord(id) {
      if (
          await this.$refs.confirmDelete.open(
              "Confirm",
              "Are you sure you want to delete this record?"
          )
      ) {
        this.deleteItem(id);
      }
    },
    async moveDevices() {
      if (
          await this.$refs.moveDialog.open(
              "Move Devices",
              "Please choose where to move the devices to"
          )
      ) {
        console.log("booya")
        for (const deviceId of this.selectedDeviceIds) {
          console.log(deviceId);
          const { Device } = this.$FeathersVuex.api
          const thisDevice = await Device.get(deviceId)
          console.log(thisDevice);
          let assignment = {
            description: 'teat descr',
            deviceId: thisDevice.id,
            fromWarehouseId: thisDevice.warehouseId,
            toWarehouseId: this.targetWarehouseId,
            userId: 2
          }
          console.log(assignment);
          this.$store.dispatch('assignments/create', assignment).then((result) => {
            toastr.success('Successfully assigned device');
            thisDevice.warehouseId = this.targetWarehouseId;
            thisDevice.patch();
            this.getwarehouse();
          }).catch((error) => {
            console.log(error)
            toastr.error(error);
          })

        }
      }
    },
    async assignDevices() {
      if (
          await this.$refs.assignDialog.open(
              "Assign Devices",
              "Please choose which partner to assign the devices to"
          )
      ) {
        for (const deviceId of this.selectedDeviceIds) {
          console.log(deviceId);
          const { Device } = this.$FeathersVuex.api
          const thisDevice = await Device.get(deviceId)
          console.log(thisDevice);
          let assignment = {
            description: 'teat partner descr',
            deviceId: thisDevice.id,
            fromPartnerId: thisDevice.warehouseId,
            toPartnerId: this.targetPartnerId,
            userId: 2
          }
          console.log(assignment);
          this.$store.dispatch('assignments/create', assignment).then((result) => {
            toastr.success('Successfully assigned device');
            thisDevice.partnerId = this.targetPartnerId;
            thisDevice.patch();
            this.getwarehouse();
          }).catch((error) => {
            console.log(error)
            toastr.error(error);
          })

        }
      }
    }
  },
  mounted() {
    this.getwarehouse();
  }
}
</script>
<style>
dl {
  padding: 0.5em;
}
dt {
  float: left;
  clear: left;
  width: 400px;
  text-align: right;
  font-weight: bold;
  color: green;
}
dt::after {
  content: ": ";
}
dd {
  margin: 0 0 0 110px;
  padding: 0 0 0.7em 0;
}
</style>
